<template>
  <layout-stepper>
    <template #body>
      <b-link @click="$router.push({name: 'step_title'})" v-if="!has_slug">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        Previous Step
      </b-link>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">Now give your listing a description</h1>
            <p>Write 3-4 sentences introducing yourself to potential buyers and let them know what makes your listing unique. 
              How does your content stand out from other creators? Why should they choose your listing?</p>
            <b-form-textarea v-model="text" rows="6" class="mt-2"></b-form-textarea>

            <b-col class="col-12 d-flex justify-content-center">
              <b-button variant="primary" class="my-2" :disabled="text === ''" @click="save()">{{has_slug ? 'Save and preview' : 'Next step'}}</b-button>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BFormTextarea,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/creator/layoutStepper.vue';
import utils from '@/libs/utils';
import creator_services from '@/services/creator';

export default {
  name: 'stepTitle',
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BFormTextarea,
    layoutStepper,
  },
  data() {
    return {
      text: '',
      profile: {},
      utils,
      steps: null,
      proposal: {}
    }
  },
  created() {
    this.getData();
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    getData() {
      if (this.$route.params.slug !== undefined) { 
        creator_services.detailProposal(this.$route.params.slug).then((response) => {
          this.text = response.description;
          this.proposal = response;
        });
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        const default_text = `I'll make (combination) to the work on my ${utils.capitalize(this.steps.network_selected.network)}Account @${this.steps.network_selected.username}`
        if (this.steps.description && this.steps.description !== default_text) this.text = this.steps.description
        else this.text = default_text;
      }
    },
    save() {
      if (this.$route.params.slug !== undefined) { 
        creator_services.updateProposal(this.proposal.uuid, {description: this.text}).then(() => {
          this.$router.push({name: 'step_preview', params: {slug: this.proposal.slug}})
        });
      } else {
        this.steps['description'] = this.text;
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({name: 'step_location'});
      }
    },
  }
}
</script>